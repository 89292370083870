import { useDataCache } from '@newageerp/v3.app.data-cache-provider';
import React, { Fragment } from 'react'
import { MP } from '@newageerp/crm-ui'
import RealtimeCampaignTable from './RealtimeCampaignTable';

export default function RealtimeCampaign() {
  const { system: dsSystem } = useDataCache();
  const { settings } = dsSystem.data;

  if (!settings?.realtime?.project) return <Fragment />

  return (
    <MP.GlobalProvider settings={{
      apiKey: settings.mp.apiKey,
      baseUrl: 'https://mp.anl.crm.wtf/api/storage',
    }}>
      <MP.InfoProvider dataSource={'realtime-campaign'} permissions={{
        doFilter: false,
      }}
        user={settings?.realtime?.project}
      >
        <RealtimeCampaignTable />
      </MP.InfoProvider>
    </MP.GlobalProvider>
  )
}
