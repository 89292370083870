import z from 'zod'

const dfFields = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
] as const;

export const DataCacheSchema: {
        [key: string]: z.ZodEnum<[string, ...string[]]>
    } = {
    'ad-cost': z.enum([...dfFields]),
    'country-group': z.enum([...dfFields]),
    'country': z.enum([...dfFields]),
    'customer': z.enum([...dfFields, 'email']),
    'klaviyo-event-sync': z.enum([...dfFields]),
    'order-item': z.enum([...dfFields]),
    'order-item-sku-group': z.enum([...dfFields]),
    'payment': z.enum([...dfFields, 'refundRequests:id']),
    'payment-refund-request': z.enum([...dfFields, 'status', 'createdAt']),
    'quiz-result': z.enum([...dfFields]),
    'report-item': z.enum([...dfFields]),
    'shipping-order': z.enum([...dfFields]),
    'subscription-cancel': z.enum([...dfFields]),
    'subscription-cancel-reason': z.enum([...dfFields]),
    'subscription-cancel-request': z.enum([...dfFields]),
    'subscription': z.enum([...dfFields, 'active', 'source', 'startDate', 'finishDate']),
    'subscription-payment': z.enum([...dfFields]),
    'subscription-price': z.enum([...dfFields]),
    'subscription-support-report': z.enum([...dfFields]),
    'user': z.enum([...dfFields, 'fullName']),
}