import { Button, Card, Form, MP, Table } from '@newageerp/crm-ui'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { RealtimeV3DateSelection } from '../RealtimeV3/types';
import { appUserChartConfig, paymentRefundChartConfig, paymentCharbeBackChartConfig, reportItemChartConfig, subscriptionCancelChartConfig } from './chart-config.types';
import { appUserFilter, paymentChargeBackFilter, paymentRefundFilter, reportItemFilter, subscriptionCancelFilter } from './filters';
import classNames from 'classnames';
import { DashboardGrossV2borderRight } from '../DashboardGrossV2';
import { useLocalStorage, useUList } from '@newageerp/v3.bundles.hooks-bundle';
import CountryFilter, { CountryFilterCountry } from '../Components/CountryFilter';
import CountryGroupFilter, { CountryGroupFilterGroup } from '../Components/CountryGroupFilter';
import { Compact, CompactRow, FieldLabel } from '@newageerp/v3.bundles.form-bundle';
import FunnelFilter from '../Components/FunnelFilter';
import AdSourceFilter from '../Components/AdSourceFilter';
import SubPeriodFilter from '../Components/SubPeriodFilter';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const tbl = Table.default;

type PaymentRefundResp = {
    calendar_week: string,
    main_order_id_countDistinct: number,
}
type PaymentChargeBackResp = {
    compl_calendar_week: string,
    main_order_id_countDistinct: number,
}
type ReportItemResp = {
    calendar_week: string,
    order_id_countDistinct: number,
}
type AppUserResp = {
    calendar_week: string,
    is_registered_sum: number,
}
type SubscriptionCancelResp = {
    calendar_week: string,
    subscription: {
        order_id_countDistinct: number,
    }
}

type DataItem = {
    week: number,
    sales: number,

    registered: number,
    registeredCr: number,

    cancelations: number,
    cancelationsCr: number,

    refunds: number,
    refundsCr: number,

    chargeBacks: number,
    chargeBacksCr: number,
}

const crFunc = (a: number, b: number) => Math.round(((a > 0 ? b / a * 100 : 0)) * 100) / 100;
const dataSum = (data: any[], k: string) => data.map(el => el[k]).reduce((a, b) => a + b, 0)

export default function SubscriptionsReport1Body() {
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState<DataItem[]>([]);

    const dtNow = moment().utcOffset('-0700');

    const defDates = {
        dateFrom: dtNow.clone().subtract(120, 'days').format('YYYY-MM-DD'),
        dateTo: dtNow.format('YYYY-MM-DD'),
    };

    const clearState = () => {
        setDates(defDates);
    }
    const [dates, setDates] = useState<RealtimeV3DateSelection>(defDates);

    const [countries, setCountries] = useState<string[]>([]);
    const [adSources, setAdSources] = useLocalStorage('DashboardAdSourceFilter', []);
    const [funnels, setFunnels] = useLocalStorage('DashboardFunnelFilter', []);
    const [plan, setPlan] = useState('');

    const [countriesReq, countriesData] = useUList<CountryFilterCountry>("country", ['id', 'name']);
    const [countryGroupsReq, countryGroupsData] = useUList<CountryGroupFilterGroup>("country-group", ['id', 'name', 'countries', 'countriesExclude']);
    const [countryGroup, setCountryGroup] = useState(0);

    useEffect(() => {
        countriesReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
        countryGroupsReq(
            {},
            1,
            9999,
            [{ key: 'i.name', value: 'ASC' }]
        );
    }, []);


    const [doReqReportItem] = MP.useMpChartData<ReportItemResp>({
        config: reportItemChartConfig,
        dataSource: 'crm.report_item',
        fields: ['calendar_week']
    });
    const [doReqAppUser] = MP.useMpChartData<ReportItemResp>({
        config: appUserChartConfig,
        dataSource: 'crm.view_app_users',
        fields: ['calendar_week']
    });
    const [doReqSubscriptionCancel] = MP.useMpChartData<ReportItemResp>({
        config: subscriptionCancelChartConfig,
        dataSource: 'crm.subscription_cancel',
        fields: ['calendar_week', 'subscription.report_item.test_user']
    });

    const [doReqRefund] = MP.useMpChartData<PaymentRefundResp>({
        config: paymentRefundChartConfig,
        dataSource: 'crm.payment',
        fields: ['calendar_week']
    });
    const [doReqChargeBack] = MP.useMpChartData<PaymentChargeBackResp>({
        config: paymentCharbeBackChartConfig,
        dataSource: 'crm.payment',
        fields: ['compl_calendar_week']
    });

    const loadData = async () => {
        setIsLoading(true);

        const resReportItem = await doReqReportItem(reportItemFilter({ dates, countries, funnels, plan: [plan] }));
        const reportItemData: ReportItemResp[] = resReportItem.data.data;

        const resAppUser = await doReqAppUser(appUserFilter({ dates, countries, funnels, plan: [plan] }));
        const appUserData: AppUserResp[] = resAppUser.data.data;

        const resSubscriptionCancel = await doReqSubscriptionCancel(subscriptionCancelFilter({ dates, countries, funnels, plan: [plan] }));
        const subscriptionCancelData: SubscriptionCancelResp[] = resSubscriptionCancel.data.data;


        const resRefund = await doReqRefund(paymentRefundFilter({ dates, countries, funnels, plan: [plan] }));
        const refundData: PaymentRefundResp[] = resRefund.data.data;

        const resChargeBack = await doReqChargeBack(paymentChargeBackFilter({ dates, countries, funnels, plan: [plan] }));
        const chargeBackData: PaymentChargeBackResp[] = resChargeBack.data.data;

        const _weeks = [
            ...reportItemData.map((e) => parseInt(e.calendar_week)),
            ...appUserData.map((e) => parseInt(e.calendar_week)),
            ...subscriptionCancelData.map((e) => parseInt(e.calendar_week)),
            ...refundData.map((e) => parseInt(e.calendar_week)),
            ...chargeBackData.map((e) => parseInt(e.compl_calendar_week)),
        ]
            .filter((value, index, array) => array.indexOf(value) === index)
            .sort((a, b) => (a === b ? 0 : (a < b ? 1 : -1)));

        setData(
            _weeks.map((w: number): DataItem => {
                const wStr = w.toFixed(0);
                const sales = reportItemData.filter((e) => e.calendar_week === wStr).map(e => e.order_id_countDistinct).reduce((a, b) => a + b, 0);
                const registered = appUserData.filter((e) => e.calendar_week === wStr).map(e => e.is_registered_sum).reduce((a, b) => a + b, 0);
                const cancelations = subscriptionCancelData.filter((e) => e.calendar_week === wStr).map(e => e.subscription.order_id_countDistinct).reduce((a, b) => a + b, 0);

                const refunds = refundData.filter((e) => e.calendar_week === wStr).map(e => e.main_order_id_countDistinct).reduce((a, b) => a + b, 0);
                const chargeBacks = chargeBackData.filter((e) => e.compl_calendar_week === wStr).map(e => e.main_order_id_countDistinct).reduce((a, b) => a + b, 0);

                return {
                    week: w,
                    sales,
                    registered,
                    registeredCr: crFunc(sales, registered),
                    cancelations,
                    cancelationsCr: crFunc(sales, cancelations),
                    refunds,
                    refundsCr: crFunc(sales, refunds),
                    chargeBacks,
                    chargeBacksCr: crFunc(sales, chargeBacks),
                }
            })
        );

        setIsLoading(false);
    }

    useEffect(() => {
        loadData().catch(console.error)
    }, []);

    return (
        <div className='space-y-4'>
            <Card.WhiteCard>
                <div className='flex flex-wrap gap-x-4 gap-y-2 items-end'>
                    <Compact>
                        <CompactRow
                            label={<FieldLabel>{('Date')}</FieldLabel>}
                            control={
                                <Form.DateRangeInput dates={dates} setDates={setDates} onClear={clearState} compact={true} />
                            }
                        />
                    </Compact>
                    <CountryFilter
                        countries={countries}
                        countriesData={countriesData.data.data}
                        setCountries={setCountries}
                    />
                    <CountryGroupFilter
                        countriesData={countriesData.data.data}
                        countryGroup={countryGroup}
                        countryGroupsData={countryGroupsData.data.data}
                        setCountries={setCountries}
                        setCountryGroup={setCountryGroup}
                    />

                    <FunnelFilter
                        funnels={funnels}
                        setFunnels={setFunnels}
                    />
                    {/* 
                    <AdSourceFilter
                        adSources={adSources}
                        setAdSources={setAdSources}
                    /> */}

                    <SubPeriodFilter
                        period={plan}
                        setPeriod={setPlan}
                    />

                </div>
                <div>
                    <Button.FormButton color={"sky"} iconName='filter' disabled={isLoading} onClick={loadData}>Filter</Button.FormButton>
                </div>
            </Card.WhiteCard>

            <Card.WhiteCard>
                {isLoading && <Skeleton count={5} />}
                {!isLoading &&
                    <tbl.Table
                        thead={
                            <thead>
                                <tr>
                                    <tbl.Th>Week</tbl.Th>
                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>Sales</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>Onboarded</tbl.Th>
                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>%</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>Cancelled</tbl.Th>
                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>%</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>Refunded</tbl.Th>
                                    <tbl.Th props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>%</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>Complain</tbl.Th>
                                    <tbl.Th props={{ className: 'text-right' }}>%</tbl.Th>
                                </tr>
                            </thead>
                        }
                        tbody={
                            <tbody>
                                {data.map(el => {
                                    return (<tr key={`w-${el.week}`}>
                                        <tbl.Td>{el.week}</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.sales}</tbl.Td>
                                        <tbl.Td props={{ className: 'text-right' }}>{el.registered}</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.registeredCr}%</tbl.Td>
                                        <tbl.Td props={{ className: 'text-right' }}>{el.cancelations}</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.cancelationsCr}%</tbl.Td>
                                        <tbl.Td props={{ className: 'text-right' }}>{el.refunds}</tbl.Td>
                                        <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{el.refundsCr}%</tbl.Td>
                                        <tbl.Td props={{ className: 'text-right' }}>{el.chargeBacks}</tbl.Td>
                                        <tbl.Td props={{ className: 'text-right' }}>{el.chargeBacksCr}%</tbl.Td>
                                    </tr>)
                                })}
                                <tr className='bg-blue-50 font-bold'>
                                    <tbl.Td>{"Total"}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{dataSum(data, 'sales')}</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{dataSum(data, 'registered')}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(dataSum(data, 'sales'), dataSum(data, 'registered'))}%</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{dataSum(data, 'cancelations')}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(dataSum(data, 'sales'), dataSum(data, 'cancelations'))}%</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{dataSum(data, 'refunds')}</tbl.Td>
                                    <tbl.Td props={{ className: classNames('text-right', DashboardGrossV2borderRight) }}>{crFunc(dataSum(data, 'sales'), dataSum(data, 'refunds'))}%</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{dataSum(data, 'chargeBacks')}</tbl.Td>
                                    <tbl.Td props={{ className: 'text-right' }}>{crFunc(dataSum(data, 'sales'), dataSum(data, 'chargeBacks'))}%</tbl.Td>
                                </tr>
                            </tbody>
                        }
                    />
                }
            </Card.WhiteCard>
        </div>
    )
}
