export const HomePageEvents = [
    'Home',
    'HomePage',
    'Landing'
]

export const QuizStartEvents = [
    'QuizStart',
    'StartQuiz',
]

export const QuizFinishEvents = [
    'QuizFinish',
    'FinishQuiz',
]

export const CheckoutPageEvents = [
    'Checkout',
    'CheckoutPage',
]

export const PurchaseEvents = [
    'Paid',
    'Purchase'
]

export const RealtimeV3AvailableColumnPresets = [
    {
        title: 'HB performance',
        events: [
            'HB-Landing',
            'HB-Header-Click',
            'HB-Banner-Click',
            'HB-Image-Click'
        ],
        cr: true,
    },
    {
        title: 'Quiz performance',
        events: [
            ...HomePageEvents,
            ...QuizStartEvents,
            'Q-000',
            'Q-001',
            'Q-002',
            'Q-003',
            'Q-004',
            'Q-005',
            'Q-006',
            'Q-007',
            'Q-008',
            'Q-009',
            'Q-010',
            'Q-011',
            'Q-012',
            'Q-013',
            'Q-014',
            'Q-015',
            'Q-016',
            'Q-017',
            'Q-018',
            'Q-019',
            'Q-020',
            'Q-021',
            'Q-022',
            'Q-023',
            'Q-024',
            'Q-025',
            'Q-026',
            'Q-027',
            'Q-028',
            'Q-029',
            'Q-030',
            'Q-031',
            'Q-032',
            'Q-033',
            'Q-034',
            'Q-035',
            'Q-036',
            'Q-037',
            'Q-038',
            'Q-039',
            'Q-040',
            'Q-041',
            'Q-042',
            'Q-043',
            'Q-044',
            'Q-045',
            'Q-046',
            'Q-047',
            'Q-048',
            'Q-049',
            ...QuizFinishEvents
        ],
        drop: true,
    },
    {
        title: 'HB + Sales',
        events: [
            'HB-Landing',
            ...HomePageEvents,
            ...QuizStartEvents,
            ...QuizFinishEvents,
            'LeftEmail',
            ...CheckoutPageEvents,
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        drop: true,
    },
    {
        title: 'Sales',
        events: [
            ...HomePageEvents,
            ...QuizStartEvents,
            ...QuizFinishEvents,
            'LeftEmail',
            ...CheckoutPageEvents,
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        drop: true,
    },
    {
        title: 'HB + Media buyer',
        events: [
            'HB-Landing',
            ...HomePageEvents,
            ...CheckoutPageEvents,
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        // drop: true,
    },
    {
        title: 'Media buyer',
        events: [
            ...HomePageEvents,
            ...CheckoutPageEvents,
            'PaymentWindow',
            ...PurchaseEvents,
        ],
        cr: true,
        // drop: true,
    }
];


export const RealtimeV3AvailableGroups = [

    { value: 'attrl_advertorial.attr_value', label: 'Advertorial' },
    { value: 'funnel', label: 'Funnel' },
    { value: 'context_geo.country', label: 'Country' },
    { value: 'context_browser.origin', label: 'Origin' },
    { value: 'context_browser.os', label: 'OS' },
    { value: 'context_browser.browser', label: 'Browser' },
    { value: 'context_browser.type', label: 'Device' },
    { value: 'project', label: 'Project' },

    { value: 'utmf_source_medium', label: 'UTM First Source + Medium' },
    { value: 'utmf_source_campaign', label: 'UTM First Source + Campaign' },
    { value: 'utmf_source_content', label: 'UTM First Source + Content' },
    { value: 'utmf_source_term', label: 'UTM First Source + Term' },
    { value: 'utmf_source_medium_campaign', label: 'UTM First Source + Medium + Campaign' },
    { value: 'utmf_source_content_campaign', label: 'UTM First Source + Content + Campaign' },
    { value: 'utmf_utm_source.utm_value', label: 'UTM First Source' },
    { value: 'utmf_utm_medium.utm_value', label: 'UTM First Medium' },
    { value: 'utmf_utm_campaign.utm_value', label: 'UTM First Campaign' },
    { value: 'utmf_utm_content.utm_value', label: 'UTM First Content' },
    { value: 'utmf_utm_term.utm_value', label: 'UTM First Term' },

    { value: 'utml_source_medium', label: 'UTM Last Source + Medium' },
    { value: 'utml_source_campaign', label: 'UTM Last Source + Campaign' },
    { value: 'utml_source_content', label: 'UTM Last Source + Content' },
    { value: 'utml_source_term', label: 'UTM Last Source + Term' },
    { value: 'utml_source_medium_campaign', label: 'UTM Last Source + Medium + Campaign' },
    { value: 'utml_source_content_campaign', label: 'UTM Last Source + Content + Campaign' },
    { value: 'utml_utm_source.utm_value', label: 'UTM Last Source' },
    { value: 'utml_utm_medium.utm_value', label: 'UTM Last Medium' },
    { value: 'utml_utm_campaign.utm_value', label: 'UTM Last Campaign' },
    { value: 'utml_utm_content.utm_value', label: 'UTM Last Content' },
    { value: 'utml_utm_term.utm_value', label: 'UTM Last Term' },

];