import React, { useEffect, useState } from 'react'
import { Button, Form, Card, MP, Table, Window } from '@newageerp/crm-ui';
import { MpChartSettings, MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import { useDataCache } from '@newageerp/v3.app.data-cache-provider';
import RealtimeCampaignChart from './RealtimeCampaignChart';
import moment from 'moment';
const tbl = Table.small;


export default function RealtimeCampaignTable() {
    const { system: dsSystem } = useDataCache();
    const { settings } = dsSystem.data;

    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))

    const { dataSource } = MP.useMpInfo();
    const [campaignData, setCampaignData] = useState<any[]>([]);
    const [avgCpa, setAvgCpa] = useState(0);
    const [maxPurchases, setMaxPurchases] = useState(0);
    const [maxSpend, setMaxSpend] = useState(0);

    const [loading, setLoading] = useState(false);

    const pivotConfig: MpChartSettings = {
        "title": "Chart",
        "axisX": "campaign_name",
        // "axisY": "date_t",
        "aggregate": [
            {
                "column": "purchases",
                "function": "max",
                "title": "Test",
                "chartType": "line"
            },
            {
                "column": "spend",
                "function": "max",
                "title": "Test",
                "chartType": "line"
            }
            // {
            //     "column": "clicks",
            //     "function": "sum",
            //     "title": "Test",
            //     "chartType": "line"
            // },
            // {
            //     "column": "reach",
            //     "function": "sum",
            //     "title": "Test",
            //     "chartType": "line"
            // },

        ]
    }

    const [doReqRealtime] = MP.useMpChartData({
        config: pivotConfig,
        dataSource: dataSource
    });

    const filters: MpFilterFactorySetUniversalItem[] = [
        {
            "comparison": "and",
            "filters": [
                {
                    "class": "StringEqualFilter",
                    "props": {
                        "column": "project",
                        "value": settings?.realtime?.project,
                    }
                },
            ]
        },
        {
            "comparison": "and",
            "filters": [
                {
                    "class": "StringEqualFilter",
                    "props": {
                        "column": "created_at_us_dt",
                        "value": date,
                    }
                },
            ]
        }
    ]

    const loadData = async () => {
        setLoading(true);

        const resCampaign = await doReqRealtime(filters);
        setCampaignData(resCampaign.data.data.sort((a: any, b: any) => {
            if (a.purchases_max > b.purchases_max) return -1;
            if (a.purchases_max < b.purchases_max) return 1;
            return 0;
        }));

        const maxP = resCampaign.data.data.map((a: any) => a.purchases_max).reduce((a: number, b: number) => a + b, 0);

        const maxS = resCampaign.data.data.map((a: any) => a.spend_max).reduce((a: number, b: number) => a + b, 0);
        setMaxPurchases(maxP);
        setMaxSpend(maxS);
        setAvgCpa(maxP > 0 ? Math.round(maxS / maxP * 100) / 100 : maxS);

        setLoading(false);
    }

    useEffect(() => {
        loadData().catch(console.error);
    }, [date]);


    return (
        <div className='space-y-4'>
            <Card.WhiteCard>
                <Form.DateInput value={date} onChange={setDate} />
            </Card.WhiteCard>
            <tbl.Table
                tbody={
                    <tbody>
                        <tr>
                            <tbl.Th props={{
                                width: 100
                            }}>Spend</tbl.Th>
                            <tbl.Td>{maxSpend.toFixed(2)}</tbl.Td>
                        </tr>
                        <tr>
                            <tbl.Th props={{
                                width: 100
                            }}>Purchases</tbl.Th>
                            <tbl.Td>{maxPurchases.toFixed(0)}</tbl.Td>
                        </tr>
                        <tr>
                            <tbl.Th props={{
                                width: 100
                            }}>CPA</tbl.Th>
                            <tbl.Td>{avgCpa}</tbl.Td>
                        </tr>
                    </tbody>
                }
            />

            {campaignData.map(c => {
                return <Card.WhiteCard header={{
                    title: c.campaign_name
                }}>
                    <RealtimeCampaignChart
                        avgCpa={avgCpa}
                        maxSpend={maxSpend}
                        maxPurchases={maxPurchases}

                        filters={[
                            ...filters,
                            {
                                "comparison": "and",
                                "filters": [
                                    {
                                        "class": "StringEqualFilter",
                                        "props": {
                                            "column": "campaign_name",
                                            "value": c.campaign_name,
                                        }
                                    },
                                ]
                            }
                        ]}
                    />
                </Card.WhiteCard>
            })}
        </div>
    )
}
