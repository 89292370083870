import React, { Fragment } from 'react'
import ReportItem from './Components/ReportItem';

type Props = {
    id: number
    dataSource: string
    element: any
}

export default function ResolverElementContentBottomExtra(props: Props) {
    return (
        <Fragment>
            {props.dataSource === 'report-item' && <ReportItem element={props.element} />}
        </Fragment>
    )
}
