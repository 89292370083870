import { MpChartSettings } from '@newageerp/mp-types'

export const usageChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "calendar_week",
    "aggregate": [
        {
            "column": "order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}

export const usageTypeChartConfig : MpChartSettings = {
    "title": "Chart",
    "axisX": "calendar_week",
    "axisY": "action",
    "aggregate": [
        {
            "column": "order_id",
            "function": "countDistinct",
            "title": "Test",
            "chartType": "line"
        },
    ]
}

