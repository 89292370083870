import React, { useEffect, useState } from 'react'
import { MP, Table } from '@newageerp/crm-ui'
import { MpChartSettings, MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine, Label, ComposedChart, Bar } from 'recharts'

const tbl = Table.small;

type Props = {
    filters: MpFilterFactorySetUniversalItem[],
    avgCpa: number,
    maxSpend: number,
    maxPurchases: number,
}
const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const cpaFunc = (spend: number, purchases: number) => Math.round((purchases != 0 ? ((spend / purchases)) : spend) * 100) / 100

export default function RealtimeCampaignChart(props: Props) {
    const { dataSource } = MP.useMpInfo();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);

    const pivotConfig: MpChartSettings = {
        "title": "Chart",
        "axisX": "date_t",
        // "axisY": "date_t",
        "aggregate": [
            {
                "column": "purchases",
                "function": "sum",
                "title": "Test",
                "chartType": "line"
            },
            {
                "column": "clicks",
                "function": "sum",
                "title": "Test",
                "chartType": "line"
            },
            {
                "column": "reach",
                "function": "sum",
                "title": "Test",
                "chartType": "line"
            },
            {
                "column": "spend",
                "function": "sum",
                "title": "Test",
                "chartType": "line"
            }
        ]
    }

    const [doReqRealtime, realtimeData] = MP.useMpChartData({
        config: pivotConfig,
        dataSource: dataSource
    });


    const loadData = async () => {
        setLoading(true);

        const resCampaign = await doReqRealtime(props.filters);
        // setCampaignData(resCampaign.data.data.sort((a: any, b: any) => {
        //     if (a.purchases_sum > b.purchases_sum) return 1;
        //     if (a.purchases_sum < b.purchases_sum) return -1;
        //     return 0;
        // }));
        setData(resCampaign.data.data.filter((e: any, idx: number, arr: any[]) => {
            const lastP = idx > 0 ? arr[idx - 1].purchases_sum : 0;
            const lastS = idx > 0 ? arr[idx - 1].spend_sum : 0;

            const periodPurchases = e.purchases_sum - lastP;
            return periodPurchases > 0 || idx === 0 || idx == arr.length - 1;
        }).map((e: any, idx: number, arr: any) => {
            const lastP = idx > 0 ? arr[idx - 1].purchases_sum : 0;
            const lastS = idx > 0 ? arr[idx - 1].spend_sum : 0;

            const periodPurchases = e.purchases_sum - lastP;
            const periodSpend = Math.round((e.spend_sum - lastS) * 100) / 100;

            return {
                ...e,
                cpa: cpaFunc(e.spend_sum, e.purchases_sum),
                dtShort: e.date_t.split(" ")[1],

                periodPurchases,
                periodSpend,

                periodCpa: cpaFunc(periodSpend, periodPurchases),
            }
        }))

        setLoading(false);
    }

    useEffect(() => {
        loadData().catch(console.error);
    }, [props.filters]);

    return (
        <div>
            <ResponsiveContainer width='100%' height={150}>

                <ComposedChart data={data} title='CPA'>
                    <XAxis dataKey="dtShort" />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

                    <Line
                        dot={false}
                        // key={`line-${p.title}`}
                        type="monotone"
                        dataKey={`cpa`}
                        stroke={colors[0]}
                        name={"Day CPA"}
                    />

                    <Bar dataKey="periodCpa" barSize={5} fill={colors[1]} />
                    <Bar dataKey="periodPurchases" barSize={5} fill={colors[2]} />
                    <Bar dataKey="periodSpend" barSize={5} fill={colors[3]} />


                    <ReferenceLine y={props.avgCpa} strokeDasharray="10 10" stroke="#ccc">
                        <Label value={"AVG"} fill="#ccc" position="left" offset={30} fontSize={14} />
                    </ReferenceLine>

                </ComposedChart>

            </ResponsiveContainer>

            <tbl.Table
                tbody={
                    <tbody>
                        <tr>
                            <tbl.Th props={{
                                width: 100
                            }}>Spend</tbl.Th>
                            <tbl.Td>{Math.max(...data.map(e => e.spend_sum)).toFixed(2)}</tbl.Td>
                        </tr>
                        <tr>
                            <tbl.Th props={{
                                width: 100
                            }}>Purchases</tbl.Th>
                            <tbl.Td>{Math.max(...data.map(e => e.purchases_sum)).toFixed(0)}</tbl.Td>
                        </tr>
                        <tr>
                            <tbl.Th props={{
                                width: 100
                            }}>CPA</tbl.Th>
                            <tbl.Td>{cpaFunc(
                                Math.max(...data.map(e => e.spend_sum)),
                                Math.max(...data.map(e => e.purchases_sum))
                            )} (AVG: {props.avgCpa.toFixed(2)})</tbl.Td>
                        </tr>
                    </tbody>
                }
            />
        </div>
    )
}
