import { MpFilterFactoryItem, MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import { RealtimeV3DateSelection } from '../RealtimeV3/types'

type FilterProps = {
    dates: RealtimeV3DateSelection,
    countries: string[],
    funnels: string[],
    plan: string[],
}

export const reportItemFilter = ({ dates, countries, funnels, plan }: FilterProps): MpFilterFactorySetUniversalItem[] => {
    const searchFilters : MpFilterFactoryItem[] = [];
    if (countries.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "country",
                "value": countries.join("\n")
            }
        })
    }
    if (funnels.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "slug",
                "value": funnels.join("\n")
            }
        })
    }
    if (plan.length > 0 && plan[0] !== '') {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "period",
                "value": plan.join("\n")
            }
        })
    }
    return [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "timezone_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "test_user",
                                "value": "0"
                            }
                        },
                        ...searchFilters
                    ]
                }
            ]
        }
    ]
}

export const appUserFilter = ({ dates, countries, funnels, plan }: FilterProps): MpFilterFactorySetUniversalItem[] => {
    const searchFilters : MpFilterFactoryItem[] = [];
    if (countries.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "country",
                "value": countries.join("\n")
            }
        })
    }
    if (funnels.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "slug",
                "value": funnels.join("\n")
            }
        })
    }
    if (plan.length > 0 && plan[0] !== '') {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "period",
                "value": plan.join("\n")
            }
        })
    }
    return [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "timezone_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "test_user",
                                "value": "0"
                            }
                        },
                        ...searchFilters,
                    ]
                }
            ]
        }
    ]
}

export const subscriptionCancelFilter = ({ dates, countries, funnels, plan }: FilterProps): MpFilterFactorySetUniversalItem[] => {
    const searchFilters : MpFilterFactoryItem[] = [];
    if (countries.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "subscription.report_item.country",
                "value": countries.join("\n")
            }
        })
    }
    if (funnels.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "subscription.report_item.slug",
                "value": funnels.join("\n")
            }
        })
    }
    if (plan.length > 0 && plan[0] !== '') {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "subscription.report_item.period",
                "value": plan.join("\n")
            }
        })
    }

    return [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "subscription.report_item.test_user",
                                "value": "0"
                            }
                        },
                        {
                            "class": "DataNotInFilter",
                            "props": {
                                "column": "reason",
                                "value": ["Lifetime upgrade", "Auto cancel (failed recurring)"].join("\n")
                            }
                        },
                        ...searchFilters,
                    ]
                }
            ]
        }
    ]
}

export const paymentRefundFilter = ({ dates, countries, funnels, plan }: FilterProps): MpFilterFactorySetUniversalItem[] => {
    const searchFilters : MpFilterFactoryItem[] = [];
    if (countries.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "report_item.country",
                "value": countries.join("\n")
            }
        })
    }
    if (funnels.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "report_item.slug",
                "value": funnels.join("\n")
            }
        })
    }
    if (plan.length > 0 && plan[0] !== '') {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "report_item.period",
                "value": plan.join("\n")
            }
        })
    }

    return [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "payment_charge_info.refund_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "report_item.test_user",
                                "value": "0"
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_refund",
                                "value": 1
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_chargeback",
                                "value": 0
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_alert",
                                "value": 0
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_rdr",
                                "value": 0
                            }
                        }
                    ]
                }
            ]
        }
    ]
}

export const paymentChargeBackFilter = ({ dates, countries, funnels, plan }: FilterProps): MpFilterFactorySetUniversalItem[] => {
    const searchFilters : MpFilterFactoryItem[] = [];
    if (countries.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "report_item.country",
                "value": countries.join("\n")
            }
        })
    }
    if (funnels.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "report_item.slug",
                "value": funnels.join("\n")
            }
        })
    }
    if (plan.length > 0 && plan[0] !== '') {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "report_item.period",
                "value": plan.join("\n")
            }
        })
    }

    return [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "report_item.test_user",
                                "value": "0"
                            }
                        },
                        ...searchFilters
                    ]
                },
            ]
        },
        {
            "comparison": "or",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "payment_charge_info.charge_back_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_chargeback",
                                "value": 1
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_rdr",
                                "value": 0
                            }
                        }
                    ]
                },
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "payment_charge_info.alert_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_chargeback",
                                "value": 0
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_rdr",
                                "value": 0
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_alert",
                                "value": 1
                            }
                        }
                    ]
                },
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "payment_charge_info.rdr_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "report_item.test_user",
                                "value": "0"
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "payment_charge_info.has_rdr",
                                "value": 1
                            }
                        }
                    ]
                },
            ]
        },
    ]
}
