import { MpFilterFactoryItem, MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import { RealtimeV3DateSelection } from '../RealtimeV3/types'

type FilterProps = {
    dates: RealtimeV3DateSelection,
    registrationDates?: {from: number, to: number},

    countries: string[],
    funnels: string[],
    plan: string[],
}

export const usageFilter = ({ dates, countries, funnels, plan, registrationDates }: FilterProps): MpFilterFactorySetUniversalItem[] => {
    const searchFilters : MpFilterFactoryItem[] = [];
    if (countries.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "country",
                "value": countries.join("\n")
            }
        })
    }
    if (funnels.length > 0) {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "slug",
                "value": funnels.join("\n")
            }
        })
    }
    if (plan.length > 0 && plan[0] !== '') {
        searchFilters.push({
            "class": "DataInFilter",
            "props": {
                "column": "period",
                "value": plan.join("\n")
            }
        })
    }
    if (registrationDates) {
        searchFilters.push(
            {
                "class": "NumberBetweenFilter",
                "props": {
                    "column": "weeks_after_registration",
                    "valueFrom": registrationDates.from,
                    "valueTo": registrationDates.to
                }
            }
        )
    }
    return [
        {
            "comparison": "and",
            "filters": [
                {
                    "comparison": "and",
                    "filters": [
                        {
                            "class": "DateBetweenFilter",
                            "props": {
                                "column": "action_date",
                                "valueFrom": dates.dateFrom,
                                "valueTo": dates.dateTo
                            }
                        },
                        {
                            "class": "NumberEquationFilter",
                            "props": {
                                "column": "test_user",
                                "value": "0"
                            }
                        },
                        ...searchFilters
                    ]
                }
            ]
        }
    ]
}
