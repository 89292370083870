import React, { Fragment, useEffect, useState } from 'react'
import { MpChartSettings, MpFilterFactorySetUniversalItem } from '@newageerp/mp-types'
import { Button, Form, MP, Table, Window } from '@newageerp/crm-ui';
import classNames from 'classnames';
import { RealtimeV3GroupingRowItem } from './types';
import { RealtimeV3AvailableColumnPresets } from './data';
import RealtimeV3DataExplore from './RealtimeV3DataExplore';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
    filters: MpFilterFactorySetUniversalItem[],
    item: RealtimeV3GroupingRowItem,
}

export default function RealtimeV3Data(props: Props) {
    const [showCr, setShowCr] = useState(false);
    const [showDrop, setShowDrop] = useState(false);

    const [loading, setLoading] = useState(false);

    const [explore, setExplore] = useState('');

    const { dataSource } = MP.useMpInfo();
    const [data, setData] = useState<any[]>([]);

    const pivotConfig: MpChartSettings = {
        "title": "Chart",
        "axisX": "name",
        "axisY": props.item.group,
        "aggregate": [
            {
                "column": "uuid",
                "function": "count",
                "title": "Test",
                "chartType": "line"
            }
        ]
    }
    const [doReqRealtime] = MP.useMpChartData({
        config: pivotConfig,
        dataSource: dataSource
    });

    const loadData = async () => {
        setLoading(true);

        const r1 = RealtimeV3AvailableColumnPresets.find(e => e.title === props.item.fields)

        const resIncome = await doReqRealtime([
            ...props.filters,
            {
                "comparison": "and",
                "filters": [
                    {
                        "class": "DataInFilter",
                        "props": {
                            "column": "name",
                            "value": (r1 ? r1.events : ["-"]).join("\n"),
                        }
                    },
                ]
            }
        ]);
        const dataIncome = resIncome.data.data;
        setData(dataIncome);

        setLoading(false);
    }

    useEffect(() => {
        loadData().catch(console.error)
    }, [props.filters]);

    const filteredData = data.filter(el => eventsFilterUtil(RealtimeV3AvailableColumnPresets.find(e => e.title === props.item.fields), el.name));

    return (
        <Fragment>
            {loading && <Skeleton count={5} />}
            {!loading &&
                <RealtimeV3DataTable
                    data={filteredData}
                    axisY={props.item.group}
                    cr={{
                        get: showCr,
                        set: setShowCr
                    }}
                    drop={{
                        get: showDrop,
                        set: setShowDrop
                    }}
                    explore={{
                        get: explore,
                        set: setExplore
                    }}
                />
            }
            {!!explore && <Window.Popup onClick={() => setExplore('')} title="Explore" isPopup={true}>

                <RealtimeV3DataExplore
                    parentItem={props.item}
                    filters={
                        [
                            ...props.filters,
                            {
                                "comparison": "and",
                                "filters": [
                                    {
                                        "class": "StringEqualFilter",
                                        "props": {
                                            "column": props.item.group,
                                            "value": explore,
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                />

            </Window.Popup>}
        </Fragment>

    )
}

const eventsFilterUtil = (ev: any, name: string) => {
    if (ev.events.indexOf(name) >= 0) return true;
    for (const _ev of ev.events) {
        if (_ev.indexOf('/') === 0) {
            const r = new RegExp(_ev.slice(1, -1));
            if (r.test(name)) return true;
        }
    }
    return false;
}

type RealtimeV3DataTableProps = {
    data: any[],
    axisY?: string,

    cr: {
        get: boolean,
        set: (v: boolean) => void,
    },
    drop: {
        get: boolean,
        set: (v: boolean) => void,
    },
    explore: {
        get: string,
        set: (v: string) => void,
    }
}

const RealtimeV3DataTable = (props: RealtimeV3DataTableProps) => {

    const [search, setSearch] = useState('');

    const { data: unFilterData } = props;
    const data = unFilterData.filter((el) => {
        if (!search) return true;
        const v = getValueByPath(el, props.axisY ? props.axisY : 'name');
        return (v ? v.toLowerCase() : '').indexOf(search.toLowerCase()) >= 0;
    })

    const tbl = Table.default;

    const distinctCols = data.map(el => getValueByPath(el, 'name')).filter((value, index, array) => array.indexOf(value) === index);
    const distinctRows = props.axisY ? data.map(el => getValueByPath(el, props.axisY ? props.axisY : 'name')).filter((value, index, array) => array.indexOf(value) === index) : [];

    const distinctRowsSort = distinctRows.map(x => ({
        name: x,
        count: data.filter(el => getValueByPath(el, props.axisY ? props.axisY : 'name') === x && (el.name === 'Paid' || el.name === 'Purchase')).map(el => el.uuid_count).reduce((a, b) => a + b, 0)
    })).sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
    }).map(el => el.name);
    const distinctColsSort = distinctCols.map(x => ({
        name: x,
        count: data.filter(el => el.name === x).map(el => el.uuid_count).reduce((a, b) => a + b, 0)
    })).sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
    }).map(el => el.name);

    return (
        <div className='space-y-4'>
            {/* {props.cr && <p>Showing conversion rate</p>}
            {props.drop && <p>Showing drop</p>} */}

            <div className='flex items-center gap-6'>
                <Window.list.filter.toolbar.searchBar width='w-full' value={search} onSearch={setSearch} instantSearch={true} />

                <div className='flex items-center gap-2'>
                    <p>CR</p>
                    <Form.Switch enabled={props.cr.get} setEnabled={props.cr.set} />
                </div>
                <div className='flex items-center gap-2'>
                    <p>Drop</p>
                    <Form.Switch enabled={props.drop.get} setEnabled={props.drop.set} />
                </div>

            </div>

            <tbl.Table
                thead={
                    <thead>
                        <tr>
                            <tbl.Th>{""}</tbl.Th>
                            {distinctColsSort.map((col, colIdx) => <Fragment key={`c-${colIdx}`}>
                                <tbl.Th props={{ className: 'text-right' }}>{col}</tbl.Th>
                                {props.drop.get && colIdx > 0 && <tbl.Th props={{ className: 'text-right' }}>{''}</tbl.Th>}
                                {props.cr.get && colIdx > 0 && <tbl.Th props={{ className: 'text-right' }}>{''}</tbl.Th>}
                            </Fragment>)}
                        </tr>
                    </thead>
                }
                tbody={
                    <tbody>
                        <RealtimeV3DataTableRow
                            row={"Total"}
                            distinctColsSort={distinctColsSort}
                            rowData={data}
                            axisY={props.axisY}
                            cr={props.cr.get}
                            drop={props.drop.get}
                        />
                        {distinctRowsSort.map((row, rowIdx) => {
                            return (
                                <RealtimeV3DataTableRow
                                    key={`r-${rowIdx}`}
                                    row={row}
                                    distinctColsSort={distinctColsSort}
                                    rowData={data.filter(el => getValueByPath(el, props.axisY ? props.axisY : 'name') === row)}
                                    axisY={props.axisY}
                                    cr={props.cr.get}
                                    drop={props.drop.get}
                                    explore={props.explore}
                                />
                            )
                        })}
                        <RealtimeV3DataTableRow
                            row={"Total"}
                            distinctColsSort={distinctColsSort}
                            rowData={data}
                            axisY={props.axisY}
                            cr={props.cr.get}
                            drop={props.drop.get}
                        />
                    </tbody>
                }
            />
        </div>
    );
}

type RealtimeV3DataTableRowProps = {
    row: string
    distinctColsSort: string[]
    rowData: any[]
    cr?: boolean,
    drop?: boolean,
    axisY?: string,
    explore?: {
        get: string,
        set: (v: string) => void,
    }
}

const RealtimeV3DataTableRow = (props: RealtimeV3DataTableRowProps) => {
    let rowValue = 0;
    let rowLastValue = 0;
    const tbl = Table.default;


    const { row, distinctColsSort, rowData } = props;

    return (
        <tr
            className={
                classNames(
                    { 'font-medium bg-blue-50': props.row === 'Total', 'cursor-pointer': !!props.explore }
                )
            }
            onClick={() => props.explore?.set(row)}
        >
            <tbl.Td>
                {row}
            </tbl.Td>
            {distinctColsSort.map((col, colIdx) => {
                let value = 0;
                const els = rowData.filter(el => (getValueByPath(el, 'name') === col));
                if (els.length > 0) {
                    value = els.map(e => e.uuid_count).reduce((a, b) => a + b, 0)
                }

                // CR and drop
                let cr = 0;
                let drop = 0;
                if (colIdx > 0) {
                    cr = rowValue > 0 ? value / rowValue * 100 : 0;
                    drop = rowLastValue > 0 ? 100 - ((value / rowLastValue) * 100) : 0;
                }

                if (colIdx === 0) rowValue = value;
                rowLastValue = value;

                return <Fragment key={`c-${colIdx}`}>
                    <tbl.Td props={{ className: 'text-right' }}><span className='whitespace-nowrap pl-6'>{value.toFixed(0)}</span></tbl.Td>
                    {props.drop && colIdx > 0 && <tbl.Td props={{ className: classNames('text-right text-xs') }}>
                        <span className={classNames(
                            { 'text-red-500': drop > 0 }
                        )}>{`${drop > 0 ? `-${drop.toFixed(1)}%` : ''}`}</span>
                    </tbl.Td>}
                    {props.cr && colIdx > 0 && <tbl.Td props={{ className: 'text-right text-xs' }}>{cr.toFixed(2)}%</tbl.Td>}

                </Fragment>
            })}
        </tr>
    )
}

const getValueByPath = (el: any, path: string) => {
    let value: any = "";
    try {
        value = path.split('.').reduce((previous: any, current: any) => previous[current], el);
    } catch (e) {
    }
    return value;
}