import { useDataCache } from '@newageerp/v3.app.data-cache-provider';
import React, { Fragment } from 'react'
import { MP } from '@newageerp/crm-ui'
import SubscriptionsReport2Body from './SubscriptionsReport2Body';

export default function SubscriptionsReport2() {
    const { system: dsSystem } = useDataCache();
    const { settings } = dsSystem.data;

    if (!settings?.realtime?.project) return <Fragment />

    return (
        <MP.GlobalProvider settings={{
            apiKey: settings.mp.apiKey,
            baseUrl: settings.mp.baseUrl,
        }}>
            <SubscriptionsReport2Body />
        </MP.GlobalProvider>
    )
}
